import * as React from "react"
import ContentPageLayout from "../../../components/ContentPageLayout"
import ContentSkeleton from "../../../components/ContentSkeleton"
import Seo from "../../../components/seo"
import Section from "../../../components/content/section"
import { getBlock, getSection } from "../../../api/content"
import SCButton from "../../../components/buttons/SCButton"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import firebase from "firebase"
import config, { firebaseInit } from "../../../config"

const BlockPage = props => {
  //   const data = graphql`
  //   query {
  //     images(id: { eq: $Id }) {
  //       title
  //       body
  //       author
  //       avatar {
  //         childImageSharp {
  //           gatsbyImageData(
  //             width: 200

  //           )
  //         }
  //       }
  //     }
  //   }
  // `
  // const image = getImage(data)
  // console.log(image)

  const profileUri = `${config.serverUri}/users`;
  const [contentLoading, setContentLoading] = React.useState(true)
  const [section, setSection] = React.useState()
  const [sectionId] = React.useState(props.params.section)
  const [blockId] = React.useState(props.params.block)
  const [userHasSuscription, setUserHasSuscription] = React.useState(null)
  const [sectionSelected, setSectionSelected] = React.useState("home")
  
  const handleSectionFilterChange = (filteredId) => {
    setSectionSelected(filteredId)
  }

  React.useEffect(() => {
    firebaseInit(firebase)
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        fetch(`${profileUri}?email=${user.email}`)
          .then(response => {
            if (response) {
              response.json().then(dataUser => {
                setUserHasSuscription(dataUser[0].suscription)
              })
            }
          }).catch(error => {
            console.log(error)
          }).catch(error => {
            console.log(error)
          })
      } else {
        console.log("user is null")
        setUserHasSuscription(false)
      }
    })
  }, [])

  React.useEffect(() => {
    getBlock(sectionId, blockId).then(data => {
      console.log(data)
      setSection(data)
      setContentLoading(false)
    })
  }, [props.params.name])

  return (
    <ContentPageLayout location={props.location} sectionSelected={sectionSelected}>
      <div className="hidden md:block">
        <Seo title={props.params.name} />
        {contentLoading || !section ? (
          <ContentSkeleton></ContentSkeleton>
        ) : (
          <Section key={section._id} section={section} userHasSuscription={userHasSuscription ?? null} onFilterChange={handleSectionFilterChange}></Section>
        )}
      </div>
      <div className="block md:hidden">
        {/* condicion = entrenamiento || home || menteycuerpo ... etc,
              condicion ? null :  */}
        {/* <GatsbyImage
          img={getImage()}  
        /> */}
        <StaticImage
          style={{ width: "100%" }}
          height={512}
          src={`../../../images/entrenamiento.png`}
          alt="Image home"
          objectFit="cover"
        />
        <div className="flex flex-col w-full h-full justify-center items-center">
          <div className="overflow w-9/12 mx-auto mt-8 mb-10">
            <h2 className="text-sc-black text-center">
              Para disfrutar de más contenido descarga la aplicación de Shift
            </h2>
          </div>
          <div className="mb-6 w-64 mx-auto rounded-full mb-20">
            <Link to="/">
              <SCButton className="rounded-full">Continuar</SCButton>
            </Link>
          </div>
        </div>
      </div>
    </ContentPageLayout>
  )
}

export default BlockPage
